import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './styles/base.css'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
)

/*
 * Prevents app being available to install due app.sheetgo.com's service worker
 */
window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault()
})
window.addEventListener('load', function() {
    document.querySelector('link[rel="manifest"]')?.remove()
})
